import 'core-js/stable'
import '@babel/polyfill'
import Vue from 'vue'
import App from './App'
import router from './router'
import CoreuiVue from '@coreui/vue'
import { iconsSet as icons } from './assets/icons/icons.js'
import store from './store'
import axios from './utils/axios';
import Spinner from "./components/Spinner";
import VueFileAgent from 'vue-file-agent';
import 'vue-file-agent/dist/vue-file-agent.css';
import Vuelidate from 'vuelidate';
import * as VueGoogleMaps from 'vue2-google-maps';
import Swal from 'sweetalert2';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';


Vue.config.performance = true

const googleApiKey = process.env.VUE_APP_GOOGLE_API_KEY;

Vue.use(CoreuiVue);
Vue.use(VueFileAgent);
Vue.use(Vuelidate);
Vue.use(VueGoogleMaps, {
  load: {
    key: googleApiKey,
    language: 'ar',
    region: 'EG',
    v: '3.26',
    libraries: 'places',
  },
  installComponents: true
});


Vue.prototype.$log = console.log.bind(console)
Vue.prototype.$http = axios;
Vue.prototype.$swal = Swal;
Vue.prototype.$tinyUploadImage = process.env.VUE_APP_TINYMC_UPLOAD_URL;


Vue.component('Spinner', Spinner);
Vue.component('v-select', vSelect);

Vue.mixin({
  methods: {
    can(key) {
      return this.$store.state.permissions.includes(key);
    }
  }
});

// Fetch permissions before application start
async function fetchPermissions() {
  try {
    const res = await axios.get('/general/user-permissions') // Ensure this URL is correct

    if (res.data.status === 200) {
      store.commit('setPermissions', res.data.data)
    } else {
      console.error("Failed to fetch permissions with status:", res.data.status)
    }
  } catch (error) {
    console.error("Error fetching permissions", error)
  }
}

function initVue() {
  new Vue({
    el: '#app',
    router,
    store,
    icons,
    template: '<App/>',
    components: {
      App
    }
  })
}


if (localStorage.getItem('admin-token') === null) {
  // create Vue instance immediately. no need to wait for permissions
  initVue()
} else {
  // Wait for permissions to be fetched before creating Vue instance
  fetchPermissions().then(() => {
    initVue()
  }).catch(error => {
    console.error("Could not initialize the app due to an error:", error);
  });
}
