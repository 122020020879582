import Vue from 'vue'
import Router from 'vue-router'
import store from '../store'

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views
const Dashboard = () => import('@/views/dashboard/Dashboard')


// Views - Pages
const Page404 = () => import('@/views/pages/Page404')
const Page403 = () => import('@/views/pages/Page403')
const Page500 = () => import('@/views/pages/Page500')


// Auth
const LoginPage = () => import('@/views/auth/Login')

// Admins
const Admins = () => import('@/views/admins/Admins')
const Admin = () => import('@/views/admins/Admin')
const CreateAdmin = () => import('@/views/admins/Create')


// Categories
const Categories = () => import('@/views/categories/Categories')
const Category = () => import('@/views/categories/Category')
const CreateCategory = () => import('@/views/categories/Create')

// Subcategories
const Subcategories = () => import('@/views/subcategories/Subcategories')
const Subcategory = () => import('@/views/subcategories/Subcategory')
const CreateSubcategory = () => import('@/views/subcategories/Create')

// Services
const Services = () => import('@/views/services/Services')
const Service = () => import('@/views/services/Service')
const CreateService = () => import('@/views/services/Create')

// States
const States = () => import('@/views/states/States')
const CreateState = () => import('@/views/states/Create')

// Cities
const Cities = () => import('@/views/cities/Cities')
const CreateCity = () => import('@/views/cities/Create')

// Users
const Users = () => import('@/views/users/Users')
const User = () => import('@/views/users/User')

// Roles
const Roles = () => import('@/views/roles/Roles')
const Role = () => import('@/views/roles/Role')
const CreateRole = () => import('@/views/roles/Create')


Vue.use(Router);

function configRoutes() {
  return [
    {
      path: '/',
      redirect: '/dashboard',
      name: 'Home',
      meta: {
        label: 'الرئيسية',
        requiresAuth: true
      },
      component: TheContainer,
      children: [
        // My Routes
        {
          path: 'dashboard',
          name: 'Dashboard',
          meta: {
            label: 'الاحصائيات',
            requiresAuth: true
          },
          component: Dashboard
        },
        
        // Users
        {
          path: 'users',
          meta: {
            label: 'المستخدمون',
            requiresAuth: true
          },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '',
              name: 'Users',
              meta: {
                label: 'المستخدمون',
                requiresAuth: true,
                permission: 'dashboard.users.list'
              },
              component: Users
            },
            {
              path: ':id',
              meta: {
                label: 'تفاصيل المستخدم',
                requiresAuth: true,
                permission: 'dashboard.users.show'
              },
              name: 'User',
              component: User
            }
          ]
        },
        
        // Categories
        {
          path: 'categories',
          meta: {
            label: 'الاقسام',
            requiresAuth: true
          },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '',
              name: 'Categories',
              meta: {
                label: 'الاقسام',
                requiresAuth: true,
                permission: 'dashboard.categories.list'
              },
              component: Categories
            },
            {
              path: 'create',
              name: 'Create Category',
              meta: {
                label: 'اضافة قسم',
                requiresAuth: true,
                permission: 'dashboard.categories.store'
              },
              component: CreateCategory
            },
            {
              path: ':id',
              meta: {
                label: 'تفاصيل القسم',
                requiresAuth: true,
                permission: 'dashboard.categories.show'
              },
              name: 'Category',
              component: Category
            },
          ]
        },
        // Subcategories
        {
          path: 'sub-categories',
          meta: {
            label: 'الاقسام الفرعية',
            requiresAuth: true
          },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '',
              name: 'Subcategories',
              meta: {
                label: 'الاقسام الفرعية',
                requiresAuth: true,
                permission: 'dashboard.subcategories.list'
              },
              component: Subcategories
            },
            {
              path: 'create',
              name: 'Create Subcategory',
              meta: {
                label: 'اضافة قسم',
                requiresAuth: true,
                permission: 'dashboard.subcategories.store'
              },
              component: CreateSubcategory
            },
            {
              path: ':id',
              meta: {
                label: 'تفاصيل القسم',
                requiresAuth: true,
                permission: 'dashboard.subcategories.show'
              },
              name: 'Subcategory',
              component: Subcategory
            },
          ]
        },

        // Services
        {
          path: 'services',
          meta: {
            label: 'الخدمات',
            requiresAuth: true
          },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '',
              name: 'Services',
              meta: {
                label: 'الخدمات',
                requiresAuth: true,
                permission: 'dashboard.services.list'
              },
              component: Services
            },
            {
              path: 'create',
              name: 'Create Service',
              meta: {
                label: 'اضافة خدمة',
                requiresAuth: true,
                permission: 'dashboard.services.store'
              },
              component: CreateService
            },
            {
              path: ':id',
              meta: {
                label: 'تفاصيل الخدمة',
                requiresAuth: true,
                permission: 'dashboard.services.show'
              },
              name: 'Service',
              component: Service
            },
          ]
        },

        // States
        {
          path: 'states',
          meta: {
            label: 'المحافظات',
            requiresAuth: true
          },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '',
              name: 'States',
              meta: {
                label: 'المحافظات',
                requiresAuth: true,
                permission: 'dashboard.states.list'
              },
              component: States
            },
            {
              path: 'create',
              name: 'Create State',
              meta: {
                label: 'اضافة محافظة',
                requiresAuth: true,
                permission: 'dashboard.states.store'
              },
              component: CreateState
            }
          ]
        },
        
        // Cities
        {
          path: 'cities',
          meta: {
            label: 'المدن',
            requiresAuth: true
          },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '',
              name: 'Cities',
              meta: {
                label: 'المدن',
                requiresAuth: true,
                permission: 'dashboard.cities.list'
              },
              component: Cities
            },
            {
              path: 'create',
              name: 'Create City',
              meta: {
                label: 'اضافة مدينة',
                requiresAuth: true,
                permission: 'dashboard.cities.store'
              },
              component: CreateCity
            }
          ]
        },

        // Admins
        {
          path: 'admins',
          meta: {
            label: 'الادارة',
            requiresAuth: true
          },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '',
              name: 'Admins',
              meta: {
                label: 'الاداري',
                requiresAuth: true,
                permission: 'dashboard.admins.list'
              },
              component: Admins
            },
            {
              path: 'create',
              name: 'Create Admin',
              meta: {
                label: 'اضافة اداري',
                requiresAuth: true,
                permission: 'dashboard.admins.store'
              },
              component: CreateAdmin
            },
            {
              path: ':id',
              meta: {
                label: 'تفاصيل الاداري',
                requiresAuth: true,
                permission: 'dashboard.admins.show'
              },
              name: 'Admin',
              component: Admin
            }
          ]
        },
        
        // Roles
        {
          path: 'roles',
          meta: {
            label: 'الصلاحيات',
            requiresAuth: true
          },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '',
              name: 'Roles',
              meta: {
                label: 'الصلاحية',
                requiresAuth: true,
                permission: 'dashboard.roles.list'
              },
              component: Roles
            },
            {
              path: 'create',
              name: 'Create Role',
              meta: {
                label: 'اضافة اداري',
                requiresAuth: true,
                permission: 'dashboard.roles.store'
              },
              component: CreateRole
            },
            {
              path: ':id',
              meta: {
                label: 'تفاصيل الصلاحية',
                requiresAuth: true,
                permission: 'dashboard.roles.show'
              },
              name: 'Role',
              component: Role
            }
          ]
        },
      ]
    },
    {
      path: '/auth',
      redirect: '/auth/login',
      name: 'Auth',
      component: {
        render(c) { return c('router-view') }
      },
      meta: {
        guest: true
      },
      children: [
        {
          path: 'login',
          name: 'Login',
          component: LoginPage
        }
      ]
    },
    {
      path: '/pages',
      redirect: '/pages/404',
      name: 'Pages',
      component: {
        render(c) { return c('router-view') }
      },
      children: [
        {
          path: '404',
          name: 'Page404',
          component: Page404
        },
        {
          path: '500',
          name: 'Page500',
          component: Page500
        },
        {
          path: '403',
          name: 'Page403',
          component: Page403
        }
      ]
    }
  ]
}


const router = new Router({
  routes: configRoutes(),
  // scrollBehavior() {
  //   document.getElementById('app').scrollIntoView();
  // },
  mode: 'hash',
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
});

router.beforeEach((to, from, next) => {
  if (to.matched.length == 0) {
    next({
      name: 'Page404',
    });
  }
  const token = localStorage.getItem('admin-token');
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (token == null) {
      next({
        path: '/auth/login',
        params: {
          nextUrl: to.fullPath
        }
      });
    } else if (to.meta.permission && !store.getters.can(to.meta.permission)) {
      // Use a Vuex getter for the `can` method or directly call the method if you've added it globally
      // Redirect or show a forbidden page if the user doesn't have permission
      next({ path: '/Pages/403' }); // Adjust as needed
    } else {
      next();
    }
  } else if (to.matched.some(record => record.meta.guest)) {
    if (token == null) {
      next();
    } else {
      next({
        name: 'Home'
      });
    }
  } else {
    next()
  }
})


export default router
