import axios from 'axios';

import store from '../store';
import router from "../router"

var instance = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    timeout: 10000,
    headers: {
        'content-type': 'application/json'
    },
});

instance.interceptors.request.use(function (config) {
    const token = localStorage.getItem("admin-token");

    if (token) {
        config.headers.Authorization = 'Bearer ' + token;
    }
    return config;
});

instance.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
}, async function (error) {
    if (error.response.status == 401) {
        console.log(error.response.status);
        localStorage.clear();

        await router.push({ name: 'Login' });
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
});


export default instance;
