import Vue from 'vue'
import Vuex from 'vuex'
import axios from './utils/axios';
Vue.use(Vuex)

const state = {
  sidebarShow: 'responsive',
  sidebarMinimize: false,
  loadSpinner: false,
  showModal: false,
  showItemDetails: false,
  permissions: [],
}

const getters = {
  can: (state) => (key) => {
    return state.permissions.includes(key);
  }
}


const mutations = {
  toggleSidebarDesktop (state) {
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarOpened ? false : 'responsive'
  },
  toggleSidebarMobile (state) {
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarClosed ? true : 'responsive'
  },
  toggleLoadSpinner (state) {
    state.loadSpinner = !state.loadSpinner
  },
  setPermissions(state, permissions) {
    state.permissions = permissions;
  },
  set (state, [variable, value]) {
    state[variable] = value
  }
}

const actions = {
  fetchPermissions({ commit }) {
    axios.get('/general/user-permissions')
      .then((res) => {
        if (res.data.status === 200) {
          commit('setPermissions', res.data.data);
        }
      })
      .catch(error => {
        console.error("Error fetching permissions", error);
      });
    }
  }


export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions
})